

.swiper-slide {
    text-align: center;
    font-size: 18px;
  
    justify-content: center;
    align-items: center;
    margin-bottom: 70px !important;
  }
  

  .swiper-pagination {
    bottom: 0px !important;
    }
  
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
  }
  
  .swiper-pagination-bullet-active {
    color: #fff;
    background: white;
  }
  